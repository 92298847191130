import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../components/Layout'

const Amenities = ({data}) => (
  <Layout>
    <div className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-7 is-offset-1">
            <h1 className="title is-size-2">Amenities</h1>
        <div className="content">
            <p>The Ricewood Municipal Utility District’s board of directors is dedicated to ensuring a high quality of life for the district’s residents. As a part of our dedication to that cause, we have created and maintain outdoor facilities throughout the district including Ricewood park and rights of way with sidewalks.</p>
            <a href="/ricewood-park">
            <div className="card">
              <div className="card-image">
                  <Img fluid={data.park.fluid} alt="" />
              </div>
              <div className="card-content">
                <h2 className="title" style={{ fontWeight: '600'}}>
                  Ricewood Park
                </h2>
              </div>
            </div>
            </a>
          </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

export default Amenities

export const amenitiesPageQuery = graphql`
  query AmenitiesPage {
    park: imageSharp(original: {src: {regex: "/ricewood_park_link/"}}) {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }`  
